import { useQuery } from '@apollo/client'
import { useAccessToken, useCirrus } from '@yanzi/react-cirrus'
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Flex,
  Form as UIForm,
  FormGroup,
  Label,
  SelectInput,
  TextInput,
} from '@yanzi/react-ui'
import gql from 'graphql-tag'
import React, { useEffect, useMemo, useState } from 'react'
import createPersistedState from 'use-persisted-state'
import { useActionsPipelineCreate } from '../../hooks/useActionsPipelineCreate'
import { Groups } from './__types__/Groups'
import { LocationsByGroup } from './__types__/LocationsByGroup'

interface SelectorProps {
  selectedId: string
  onSelect(id: string): void
}

const usePersistedBaseUrlState = createPersistedState('actionsBaseUrl')

function GroupSelector({ onSelect, selectedId }: SelectorProps) {
  const { data } = useQuery<Groups>(GROUPS_QUERY, {
    context: {
      linkServer: true,
    },
  })

  useEffect(() => {
    if (selectedId === '' && data?.accounts?.length) {
      onSelect(data?.accounts[0].accountNumber!)
    }
  }, [data, selectedId, onSelect])

  return (
    <FormGroup id="group">
      <Label>Group</Label>
      <SelectInput onChange={e => onSelect(e.target.value)} value={selectedId}>
        {data?.accounts.map(({ accountName, accountNumber }) => (
          <option value={accountNumber!} key={accountNumber!}>
            {accountName} ({accountNumber})
          </option>
        ))}
      </SelectInput>
    </FormGroup>
  )
}

function LocationSelector({ groupId, onSelect, selectedId }: SelectorProps & { groupId: string }) {
  const { data } = useQuery<LocationsByGroup>(LOCATIONS_QUERY, {
    variables: {
      groupId,
    },
    context: {
      linkServer: true,
    },
  })

  const sortedLocations = useMemo(() => {
    if (data?.locations) {
      return data
        .locations!.list!.slice()
        .filter(l => l.accountId === groupId)
        .sort((a, b) => (a.name || '').localeCompare(b.name || ''))
    }
    return []
  }, [data, groupId])

  useEffect(() => {
    if (sortedLocations.length) {
      onSelect(sortedLocations[0].locationId!)
    }
  }, [sortedLocations, onSelect])

  return (
    <FormGroup id="lid">
      <Label>Location</Label>
      <SelectInput onChange={e => onSelect(e.target.value)} value={selectedId}>
        {sortedLocations.map(location => (
          <option value={location.locationId!} key={location.locationId!}>
            {location.name} ({location.locationId})
          </option>
        ))}
      </SelectInput>
    </FormGroup>
  )
}

interface FormProps {
  groupId: string
  locationId: string
  type: 'coffee' | 'andreasIsAtTheOffice' | 'locationOffline'
}

function Form(props: FormProps) {
  const { groupId, locationId, type } = props
  const { host } = useCirrus()
  const { data: accessToken } = useAccessToken(locationId)
  const [baseUrl, setBaseUrl] = usePersistedBaseUrlState('https://actions.yanzi.cloud')
  const [extra, setExtra] = useState('{}')
  const create = useActionsPipelineCreate(baseUrl)

  return (
    <UIForm
      onSubmit={() => {
        create(type, { host, groupId, accessToken, locationId, ...JSON.parse(extra) })
      }}
    >
      <TextInput value={baseUrl} onChange={e => setBaseUrl(e.target.value)} />
      <FormGroup id="groupId">
        <Label>Group</Label>
        <TextInput readOnly value={groupId} required />
      </FormGroup>

      <FormGroup id="type">
        <Label>Type</Label>
        <TextInput readOnly value={type} required />
      </FormGroup>

      <input type="hidden" name="host" value={host} />
      <input type="hidden" name="accessToken" value={accessToken} />
      <input type="hidden" name="locationId" value={locationId} />

      <FormGroup id="extra">
        <Label>Extra JSON</Label>
        <TextInput value={extra} onChange={e => setExtra(e.target.value)} />
      </FormGroup>

      <Button type={'submit'} disabled={!groupId} data-test-id="submit-btn">
        Submit
      </Button>
    </UIForm>
  )
}

export function ActionsNewPipeline() {
  const [groupId, setGroupId] = useState('')
  const [locationId, setLocationId] = useState('')
  const [type, setType] = useState<'coffee' | 'andreasIsAtTheOffice'>('coffee')

  return (
    <Container center my={4}>
      <Flex wrap="wrap">
        <Box width={[1, 1, 1 / 2]}>
          <Card mx={2}>
            <CardHeader>
              <h3>Select Type</h3>
            </CardHeader>
            <CardContent>
              <FormGroup id="type">
                <Label>Type</Label>
                <SelectInput
                  onChange={e => setType(e.target.value as 'coffee' | 'andreasIsAtTheOffice')}
                >
                  <option value="coffee">Coffee</option>
                  <option value="andreasIsAtTheOffice">Andreas Desk</option>
                  <option value="locationOffline">Location offline</option>
                  <option value="deviceOffline">Device offline</option>
                </SelectInput>
              </FormGroup>
            </CardContent>
          </Card>
          <Card mx={2}>
            <CardHeader>
              <h3>Select Group</h3>
            </CardHeader>
            <CardContent>
              <GroupSelector onSelect={id => setGroupId(id)} selectedId={groupId} />
            </CardContent>
          </Card>
          <Card mx={2}>
            <CardHeader>
              <h3>Select Location</h3>
            </CardHeader>
            <CardContent>
              <LocationSelector
                groupId={groupId}
                onSelect={id => setLocationId(id)}
                selectedId={locationId}
              />
            </CardContent>
          </Card>
        </Box>
        <Box width={[1, 1, 1 / 2]}>
          <Card mx={2}>
            <CardHeader>
              <h3>Start new action pipeline</h3>
            </CardHeader>
            <CardContent>
              <Form groupId={groupId} type={type} locationId={locationId} />
            </CardContent>
          </Card>
        </Box>
      </Flex>
    </Container>
  )
}

export const GROUPS_QUERY = gql`
  query Groups {
    accounts {
      key
      accountName
      accountNumber
    }
  }
`

export const LOCATIONS_QUERY = gql`
  query LocationsByGroup($groupId: String!) {
    locations(search: $groupId) {
      numPages
      list {
        key
        name
        locationId
        accountId
      }
    }
  }
`
