import { ApolloProvider } from '@yanzi/react'
import {
  AuthenticatedCirrusProvider,
  CirrusOAuthProvider,
  CirrusProvider,
} from '@yanzi/react-cirrus'
import { ErrorNotFound } from '@yanzi/react-error'
import { Grid, GridItem, ThemeProvider } from '@yanzi/react-ui'
import React, { useCallback } from 'react'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import { ActionsNewPipeline } from './components/ActionsNewPipeline'
import { ActionsPipelineList } from './components/ActionsPipelineList'
import { CSVExportScheduler } from './components/CSVExportScheduler'
import { IoTHubConnectorDetail } from './components/IoTHubConnectorDetail'
import { IoTHubConnectorList } from './components/IoTHubConnectorList'
import { IoTHubNewConnector } from './components/IoTHubNewConnector'
import { Navigation } from './components/Navigation'

function App() {
  const graphQLErrorHandler = useCallback(e => {
    if (process.env.NODE_ENV === 'development') {
      throw e
    }
  }, [])

  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <ThemeProvider theme="connect">
        <CirrusProvider>
          <CirrusOAuthProvider>
            <AuthenticatedCirrusProvider
              authenticated={
                <ApolloProvider errorHandler={graphQLErrorHandler}>
                  <Grid
                    gap={0}
                    height="100%"
                    templateColumns="20rem 1fr"
                    backgroundColor="pageBackground"
                  >
                    <GridItem height="100%" overflowY="auto">
                      <Navigation />
                    </GridItem>
                    <GridItem height="100%" overflowY="auto">
                      <Routes />
                    </GridItem>
                  </Grid>
                </ApolloProvider>
              }
            ></AuthenticatedCirrusProvider>
          </CirrusOAuthProvider>
        </CirrusProvider>
      </ThemeProvider>
    </BrowserRouter>
  )
}

function Routes() {
  return (
    <Switch>
      <Route exact path="/connectors/iothub">
        <IoTHubConnectorList />
      </Route>
      <Route exact path="/connectors/iothub/new">
        <IoTHubNewConnector />
      </Route>
      <Route exact path="/connectors/iothub/:clientId">
        <IoTHubConnectorDetail />
      </Route>

      <Route exact path="/connectors/csv">
        <CSVExportScheduler />
      </Route>

      <Route exact path="/connectors/actions">
        <ActionsPipelineList />
      </Route>
      <Route exact path="/connectors/actions/new">
        <ActionsNewPipeline />
      </Route>

      <Redirect exact from="/" to="/connectors/iothub" />
      <Route>
        <ErrorNotFound />
      </Route>
    </Switch>
  )
}

export default App
