import { Capability } from '@yanzi/react'
import {
  Button,
  Card,
  CardContent,
  Container,
  Form,
  FormGroup,
  Label,
  SelectInput,
  TextInput,
  useErrorAlert,
} from '@yanzi/react-ui'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useIoTHubCreateConnector } from '../../hooks/useIoTHubCreateConnector'
import { IoTHubProtocol } from '../../util/configAPI'
import { LocationsFormGroup } from '../LocationsFormGroup'

export function IoTHubNewConnector() {
  const [name, setName] = useState('')
  const [errorAlert, setError] = useErrorAlert()
  const [connectionString, setConnectionString] = useState('')
  const [protocol, setProtocol] = useState<IoTHubProtocol>('AMQPS')
  const [locationIds, setLocationIds] = useState<string[]>([])

  const create = useIoTHubCreateConnector()

  const history = useHistory()

  const onSubmit = async () => {
    try {
      await create({ name, connectionString, protocol, locationIds })
      history.push('/connectors')
    } catch (e) {
      setError(e.message ?? 'Unknown network error.')
    }
  }

  return (
    <Container center pt={4}>
      <h1>New IoT Hub connector</h1>
      <Card backgroundColor="#fff" color="#000">
        <CardContent>
          <Form onSubmit={onSubmit}>
            <FormGroup id="name" mt={0}>
              <Label>Name</Label>
              <TextInput value={name} onChange={e => setName(e.target.value)} />
            </FormGroup>
            <FormGroup id="connection-string" mt={0}>
              <Label>Connection string</Label>
              <TextInput
                value={connectionString}
                onChange={e => setConnectionString(e.target.value)}
              />
            </FormGroup>
            <FormGroup id="protocol" mt={0}>
              <Label>Protocol</Label>
              <SelectInput
                value={protocol}
                onChange={e => setProtocol(e.target.value as IoTHubProtocol)}
              >
                <option value="AMQPS">AMQPS (recommended)</option>
                <Capability has="developer">
                  <option value="AMQPS_WS">AMQPS_WS</option>
                  <option value="HTTPS">HTTPS</option>
                  <option value="MQTT">MQTT</option>
                  <option value="MQTT_WS">MQTT_WS</option>
                </Capability>
              </SelectInput>
            </FormGroup>
            <LocationsFormGroup locationIds={locationIds} setLocationIds={setLocationIds} />
            <FormGroup>
              <Button mr="auto" type="submit" backgroundColor="accent">
                Create
              </Button>
            </FormGroup>
          </Form>
        </CardContent>
      </Card>
      {errorAlert}
    </Container>
  )
}
