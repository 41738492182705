import { faPen, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  BarLoader,
  Box,
  Button,
  Container,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useFormModal,
} from '@yanzi/react-ui'
import React from 'react'
import { Link } from 'react-router-dom'
import createPersistedState from 'use-persisted-state'
import { useActionsPipelineActions } from '../../hooks/useActionsPipelineActions'
import { useActionsPipelineList } from '../../hooks/useActionsPipelineList'

const usePersistedBaseUrlState = createPersistedState('actionsBaseUrl')

function ActionsHostSelector({
  baseUrl,
  setBaseUrl,
}: {
  baseUrl: string
  setBaseUrl: (arg: string) => void
}) {
  const [modal, ask] = useFormModal({
    fields: {
      baseUrl: {
        type: 'text',
        label: 'Actions Host',
        placeholder: 'Base URL for actions instance.',
        defaultValue: baseUrl,
      },
    },
  })

  return (
    <Box>
      {modal}
      <Text>Actions Host: {baseUrl}</Text>
      <Button
        variant="text"
        ml={3}
        onClick={async () => {
          const { baseUrl } = await ask()
          setBaseUrl(baseUrl)
        }}
      >
        <FontAwesomeIcon aria-label="Change actions host" size="xs" icon={faPen} />
      </Button>
    </Box>
  )
}

export function ActionsPipelineList() {
  const [baseUrl, setBaseUrl] = usePersistedBaseUrlState('https://actions.yanzi.cloud')
  const { list: pipelines, loading } = useActionsPipelineList(baseUrl)
  const { pause, start, terminate } = useActionsPipelineActions(baseUrl)

  return (
    <Container pt={4} center>
      <Flex flexWrap="wrap">
        <Box mr="auto">
          <Box as="h1" m={0} p={0}>
            Yanzi Actions Pipelines
          </Box>
        </Box>
        <Box>
          <Button
            as={props => <Link to="/connectors/actions/new" {...props} />}
            backgroundColor="accent"
            color="#fff"
            p={3}
          >
            <FontAwesomeIcon icon={faPlus} /> New
          </Button>
        </Box>
      </Flex>
      <ActionsHostSelector baseUrl={baseUrl} setBaseUrl={setBaseUrl} />
      {loading ? <BarLoader /> : null}

      {pipelines.length ? (
        <Table variant="card">
          <Thead>
            <Tr>
              <Th>ID</Th>
              <Th>Type</Th>
              <Th></Th>
              <Th></Th>
              <Th>Status</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {pipelines.map(
              ({
                id,
                options: { type, sessionId, accessToken, ...rest },
                context: { state, states },
              }) => (
                <Tr key={id}>
                  <Td>
                    {
                      // <Link to={`/connectors/actions/${id}`}>{id}</Link>
                    }
                    {id}
                  </Td>
                  <Td>{type}</Td>
                  <Td>
                    <pre>{JSON.stringify(rest, null, 2)}</pre>
                  </Td>
                  <Td>{state}</Td>
                  <Td>
                    <pre>{JSON.stringify(states)}</pre>
                  </Td>
                  <Td p={0}>
                    {state === 'running' && (
                      <Button variant="action-table-icon" onClick={() => pause({ id })}>
                        Pause
                      </Button>
                    )}
                    {state === 'paused' && (
                      <Button variant="action-table-icon" onClick={() => start({ id })}>
                        Start
                      </Button>
                    )}
                    <Button variant="action-table-icon" onClick={() => terminate({ id })}>
                      Terminate
                    </Button>
                  </Td>
                </Tr>
              ),
            )}
          </Tbody>
        </Table>
      ) : (
        <Box p={4} textAlign="center" color="#666">
          <Text as="h2">No pipelines found</Text>
          <Box as="p" maxWidth={250} mx="auto !important" my={3} display="block">
            Create new pipelines by using the button in the upper right corner.
          </Box>
        </Box>
      )}
    </Container>
  )
}
