import { gql, useQuery } from '@apollo/client'
import { useCirrus } from '@yanzi/react-cirrus'
import { FormGroup, Label, SelectInput, Spinner } from '@yanzi/react-ui'
import { orderBy } from 'lodash'
import React, { useEffect, useMemo } from 'react'
import { Locations } from './__types__/Locations'

export function LocationSelector({
  setLocationId,
  locationId,
}: {
  locationId: string
  setLocationId: (id: string) => void
}) {
  const { socket } = useCirrus()
  const { data, error, loading } = useQuery<Locations>(LOCATIONS_QUERY, {
    context: { linkServer: true, socket },
    returnPartialData: true,
    fetchPolicy: 'cache-and-network',
  })

  const sortedLocations = useMemo(() => {
    const list = data?.locations?.list
    if (!list) {
      return []
    }
    return orderBy(list, [x => x.lifeCycleState, x => x.name])
  }, [data])

  useEffect(() => {
    if (locationId === '' && sortedLocations.length) {
      setLocationId(sortedLocations[0].locationId!)
    }
  }, [sortedLocations, locationId, setLocationId])

  if (error) {
    throw error
  }

  if (!data?.locations && loading) {
    return <Spinner />
  }

  return (
    <FormGroup id="lid">
      <Label>Location</Label>
      <SelectInput onChange={e => setLocationId(e.target.value)} value={locationId}>
        {sortedLocations.map(location => (
          <option
            value={location.locationId!}
            key={location.locationId!}
            disabled={location.lifeCycleState === 'shadow'}
          >
            {location.name} ({location.locationId})
          </option>
        ))}
      </SelectInput>
    </FormGroup>
  )
}

gql`
  extend type LocationDTO {
    lifeCycleState(locationId: String, gwdid: String): String
  }
`

export const LOCATIONS_QUERY = gql`
  query Locations {
    locations(first: 1000) {
      cursor
      hasNextPage
      list {
        key
        gwdid
        name
        locationId
        account {
          key
          owner
          accountName
          accountNumber
        }
        lifeCycleState @client
        lifecycleVersion: property(name: lifecycleVersion) {
          stringValue
        }
      }
    }
  }
`
