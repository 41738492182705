import { gql, useQuery } from '@apollo/client'
import { variableNameToString } from '@yanzi/react'
import { FormGroup, Label, Spinner, Table, Tbody, Td, Th, Thead, Tr } from '@yanzi/react-ui'
import { VariableName as CirrusVariableName } from '@yanzi/socket'
import React, { useEffect, useRef } from 'react'
import { useQuery as useReactQuery } from 'react-query'
import { VariableName } from '../../__types__/globalTypes'
import { VarNames, VarNamesVariables } from './__types__/VarNames'

export function VariableNameSelector({
  slotSize,
  locationId,
  did,
  columns,
  setColumns,
}: {
  slotSize: number
  locationId: string
  did: string
  columns: { aggregation: string; variableName: VariableName }[]
  setColumns: React.Dispatch<
    React.SetStateAction<{ aggregation: string; variableName: VariableName }[]>
  >
}) {
  const { data, loading, error } = useQuery<VarNames, VarNamesVariables>(VARIABLE_NAMES_QUERY, {
    variables: { locationId, did },
    context: { locationId },
    skip: !locationId || !did,
  })

  useEffect(() => {
    if (error) {
      throw error
    }
  }, [error])

  const { data: availableVariableNames, isLoading } = useReactQuery<CirrusVariableName[]>(
    'variable-names',
    { useErrorBoundary: true },
  )

  const unitVariableNames = data?.location?.unit?.dataSources
    ?.filter(dataSource =>
      availableVariableNames?.some(varName => varName.name === dataSource.variableName),
    )
    ?.map(x => x.variableName)
    ?.filter(function<T>(x: T | null | undefined): x is T {
      return !!x
    })

  const { data: availableAggregations } = useReactQuery<{ id: string; name: string }[]>(
    'aggregations',
  )

  const lastSlotSize = useRef(slotSize)
  useEffect(() => {
    if (lastSlotSize.current !== slotSize) {
      setColumns([])
      lastSlotSize.current = slotSize
    }
  }, [setColumns, slotSize])

  return (
    <FormGroup>
      <Label htmlFor="variableName">Variable names</Label>
      <Table>
        <Thead>
          <Tr>
            {slotSize === 0 ? <Th /> : null}
            <Th>Variable name</Th>
            {slotSize !== 0 ? (
              <>
                {availableAggregations?.map(aggregation => (
                  <Th key={aggregation.id}>{aggregation.name}</Th>
                ))}
              </>
            ) : null}
          </Tr>
        </Thead>
        <Tbody>
          {unitVariableNames?.map(variableName => (
            <Tr key={variableName}>
              {slotSize === 0 ? (
                <Td shouldShrink>
                  <input
                    checked={columns.some(
                      column =>
                        column.aggregation === 'last' && column.variableName === variableName,
                    )}
                    onChange={e => {
                      if (e.target.checked) {
                        setColumns(old => [...old, { variableName, aggregation: 'last' }])
                      } else {
                        setColumns(old =>
                          old.filter(
                            x => !(x.variableName === variableName && x.aggregation === 'last'),
                          ),
                        )
                      }
                    }}
                    id={variableName}
                    type="checkbox"
                    aria-label={`Include ${variableNameToString(variableName)} in the CSV`}
                    title={`Include ${variableNameToString(variableName)} in the CSV`}
                  />
                </Td>
              ) : null}
              <Th>
                <Label htmlFor={variableName}>{variableNameToString(variableName)}</Label>
              </Th>

              {slotSize !== 0 ? (
                <>
                  {availableAggregations?.map(aggregation => (
                    <Td shouldShrink key={aggregation.id}>
                      <input
                        checked={columns.some(
                          column =>
                            column.aggregation === aggregation.id &&
                            column.variableName === variableName,
                        )}
                        onChange={e => {
                          if (e.target.checked) {
                            setColumns(old => [
                              ...old,
                              { variableName, aggregation: aggregation.id },
                            ])
                          } else {
                            setColumns(old =>
                              old.filter(
                                x =>
                                  !(
                                    x.variableName === variableName &&
                                    x.aggregation === aggregation.id
                                  ),
                              ),
                            )
                          }
                        }}
                        type="checkbox"
                        aria-label={`Include ${aggregation.name} ${variableNameToString(
                          variableName,
                        )} in the CSV`}
                        title={`Include ${aggregation.name} ${variableNameToString(
                          variableName,
                        )} in the CSV`}
                      />
                    </Td>
                  ))}
                </>
              ) : null}
            </Tr>
          ))}
          {!unitVariableNames?.length &&
            (!did ? (
              <Tr>
                <Td colSpan={2}>Select a unit to show available variable names.</Td>
              </Tr>
            ) : loading || isLoading ? (
              <Tr>
                <Td colSpan={2}>
                  <Spinner />
                </Td>
              </Tr>
            ) : (
              <Tr>
                <Td colSpan={2}>No exportable variable names available.</Td>
              </Tr>
            ))}
        </Tbody>
      </Table>
    </FormGroup>
  )
}

export const VARIABLE_NAMES_QUERY = gql`
  query VarNames($locationId: String!, $did: String!) {
    location(locationId: $locationId) {
      key
      unit(did: $did) {
        key
        dataSources {
          key
          variableName
        }
      }
    }
  }
`
