import { useQuery } from '@apollo/client'
import { BarLoader, Box, FormGroup, Input, Label } from '@yanzi/react-ui'
import gql from 'graphql-tag'
import { orderBy } from 'lodash'
import React, { Ref, RefObject } from 'react'

export function LocationsFormGroup({
  locationIds,
  setLocationIds,
  leastDestructiveRef,
}: {
  locationIds: string[]
  setLocationIds: (values: string[]) => void
  leastDestructiveRef?: RefObject<any> | Ref<any>
}) {
  const res = useQuery(GET_ALL_LOCATIONS_QUERY, {
    variables: {},
    context: {
      linkServer: true,
    },
  })
  const locationList = res.data?.locations?.list

  if (!locationList) {
    return <BarLoader />
  }

  const sortedLocations = orderBy(locationList, ['name', 'locationId'])

  return (
    <FormGroup>
      <Label htmlFor="" ref={leastDestructiveRef}>
        Locations
      </Label>
      <Box maxHeight={400} overflowY="auto" position="relative" p={2}>
        {sortedLocations.map(({ locationId, name }: Location) => (
          <FormGroup checkbox key={locationId!}>
            <Input
              type="checkbox"
              id={locationId!}
              checked={locationIds.includes(locationId!)}
              onChange={e => {
                const checked = e.target.checked
                const newLocationIdList = [
                  ...locationIds.filter(x => x !== locationId!),
                  ...(checked ? [locationId!] : []),
                ]
                setLocationIds(newLocationIdList)
              }}
            />
            <Label htmlFor={locationId!}>
              {name} ({locationId})
            </Label>
          </FormGroup>
        ))}
      </Box>
    </FormGroup>
  )
}

export const GET_ALL_LOCATIONS_QUERY = gql`
  query GetAllLocationsQuery {
    locations(first: 10000) {
      list {
        key
        locationId
        name
      }
    }
  }
`

interface Location {
  locationId: string | null
  name: string | null
}
