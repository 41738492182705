import { faExternalLinkAlt, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Capability, CapabilityBadge } from '@yanzi/react'
import { useAuthenticatedCirrus, useCirrus } from '@yanzi/react-cirrus'
import { Box, Button, Stack } from '@yanzi/react-ui'
import { Link } from 'react-router-dom'

export function Navigation() {
  const { host, socket } = useCirrus()
  const { signOut } = useAuthenticatedCirrus()

  return (
    <Box
      minHeight="100%"
      as="nav"
      position="relative"
      backgroundColor="primary"
      color="primaryText"
      role="navigation"
      pt={4}
    >
      <Box p={3}>
        <Box px={3}>
          <h3>Yanzi Connect</h3>
        </Box>
      </Box>

      <Stack direction="column" spacing={1}>
        <Box p={3}>
          <Box p={3} fontWeight="bold">
            Connectors
          </Box>
          <Stack direction="column" spacing={1}>
            <Item label="IoT Hub" path="/connectors/iothub" />
            <Item label="Yanzi CSV Exporter" path="/connectors/csv" />
            <Capability has="developer">
              <Item label="Yanzi Actions" path="/connectors/actions" />
            </Capability>
          </Stack>
        </Box>
        <Box p={3}>
          <Box p={3} fontWeight="bold">
            Shortcuts
          </Box>
          <Stack direction="column" spacing={1}>
            <Item
              label="Yanzi Lifecycle"
              href={`https://live.yanzi.cloud?host=${host}&autoLogin=${
                socket.isAuthenticatedWith === 'jwt' ? 'true' : 'false'
              }`}
            />
          </Stack>
        </Box>
      </Stack>
      <Box p={3}>
        <Button onClick={signOut}>
          <FontAwesomeIcon icon={faSignOutAlt} /> Sign out
        </Button>
      </Box>
    </Box>
  )
}

function Item({ label, path, href }: { path?: string; label: string; href?: string }) {
  return (
    <Button
      backgroundColor="transparent"
      color="inherit"
      fontWeight="normal"
      display="block"
      _hover={{ backgroundColor: 'primaryDark' }}
      as={props =>
        href ? (
          <a href={href} {...props}>
            {props.children}
          </a>
        ) : (
          <Link to={path} {...props} />
        )
      }
    >
      {label} {href ? <FontAwesomeIcon icon={faExternalLinkAlt} /> : null} <CapabilityBadge text />
    </Button>
  )
}
