import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  BarLoader,
  Box,
  Button,
  Container,
  Flex,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@yanzi/react-ui'
import React, { useCallback } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useIoTHubConnectorList } from '../../hooks/useIoTHubConnectorList'
import { useIoTHubSetStateFetcher } from '../../hooks/useIoTHubSetStateFetcher'

export function IoTHubConnectorList() {
  const { list: connectors, loading, refetch } = useIoTHubConnectorList()

  const history = useHistory()
  const setState = useIoTHubSetStateFetcher()

  const setStateAndReload = useCallback(
    async (params: { clientId: string; state: 'idle' | 'running' }) => {
      await setState(params)
      refetch()
    },
    [setState, refetch],
  )

  return (
    <Container pt={4} center>
      <Flex flexWrap="wrap">
        <Box mr="auto">
          <Box as="h1" m={0} p={0}>
            IoT Hub Connectors
          </Box>
        </Box>
        <Box>
          <Button
            as={props => <Link to="/connectors/iothub/new" {...props} />}
            backgroundColor="accent"
            color="#fff"
            p={3}
          >
            <FontAwesomeIcon icon={faPlus} /> New
          </Button>
        </Box>
      </Flex>
      {loading ? <BarLoader /> : null}

      {connectors.length === 0 ? (
        <Box p={5} textAlign="center">
          No IoTHub connectors found.
        </Box>
      ) : (
        <Table variant="card">
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Connector</Th>
              <Th></Th>
              <Th>Status</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {connectors.map(({ locationIds, clientId, state, name }) => (
              <Tr key={clientId}>
                <Td>
                  <Link to={`/connectors/iothub/${clientId}`}>{name ?? clientId}</Link>
                </Td>
                <Td>Microsoft IoT Hub</Td>
                <Td>
                  {locationIds?.length} location{(locationIds?.length ?? 0) > 1 ? 's' : ''}{' '}
                  {locationIds?.length ? (
                    <>
                      ({locationIds?.slice(0, 2).join(', ')}
                      {locationIds?.length - 2 > 0 ? ` and ${locationIds?.length - 2} more` : ''})
                    </>
                  ) : null}
                </Td>
                <Td>{state}</Td>
                <Td p={0}>
                  <Button
                    variant="action-table-icon"
                    onClick={() => history.push(`/connectors/iothub/${clientId}`)}
                  >
                    Inspect
                  </Button>{' '}
                  {state === 'running' && (
                    <Button
                      variant="action-table-icon"
                      onClick={() => setStateAndReload({ clientId, state: 'idle' })}
                    >
                      Stop
                    </Button>
                  )}
                  {state === 'idle' && (
                    <Button
                      variant="action-table-icon"
                      onClick={() => setStateAndReload({ clientId, state: 'running' })}
                    >
                      Start
                    </Button>
                  )}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}
    </Container>
  )
}
