import { FormGroup, Input, Label, SelectInput, Spinner } from '@yanzi/react-ui'
import React, { useMemo } from 'react'
import { useQuery } from 'react-query'

export function SlotSizeSelector({
  slotSize,
  setSlotSize,
}: {
  slotSize: number
  setSlotSize: React.Dispatch<React.SetStateAction<number>>
}) {
  const { data: slotSizes, isLoading } = useQuery<{ name: string; value: number }[]>('slot-sizes')
  const nonZeroSlotSizes = useMemo(() => {
    return slotSizes?.filter(x => !!x.value)
  }, [slotSizes])

  return (
    <>
      <FormGroup>
        <Label>Aggregations</Label>
        <FormGroup radio id="slots">
          <Input
            type="radio"
            name="samples-or-slots"
            id="slots"
            checked={slotSize === 0}
            onChange={e => (e.target.checked ? setSlotSize(0) : undefined)}
          />
          <Label>All samples</Label>
        </FormGroup>
        <FormGroup radio id="samples">
          <Input
            type="radio"
            name="samples-or-slots"
            id="samples"
            checked={slotSize !== 0}
            onChange={e =>
              e.target.checked ? setSlotSize(nonZeroSlotSizes?.[0]?.value ?? 0) : undefined
            }
          />
          <Label>Aggregated by time</Label>
        </FormGroup>
      </FormGroup>
      {slotSize === 0 ? null : (
        <FormGroup>
          <Label htmlFor="slotSize">Slot size</Label>
          {isLoading ? <Spinner /> : null}
          <SelectInput onChange={e => setSlotSize(Number(e.target.value))}>
            {nonZeroSlotSizes?.map(({ name, value }) => (
              <option value={value} key={value}>
                {name}
              </option>
            ))}
          </SelectInput>
        </FormGroup>
      )}
    </>
  )
}
