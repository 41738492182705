import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Capability, CapabilityBadge } from '@yanzi/react'
import { useCirrus, useCirrusSessionId } from '@yanzi/react-cirrus'
import {
  Badge,
  BasicGrid,
  BeatLoader,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Form,
  FormGroup,
  GridItem,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Stack,
  TextInput,
  useErrorAlert,
} from '@yanzi/react-ui'
import { format, parse, subDays } from 'date-fns'
import React, { useCallback, useRef, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { ReactQueryConfigProvider, useMutation } from 'react-query'
import createPersistedState from 'use-persisted-state'
import { VariableName } from '../../__types__/globalTypes'
import { LocationSelector } from './LocationSelector'
import { SlotSizeSelector } from './SlotSizeSelector'
import { UnitSelector } from './UnitSelector'
import { VariableNameSelector } from './VariableNameSelector'

const useCsvUrl = createPersistedState('csv-host')

function createDefaultQueryFunction(baseUrl: string) {
  return (key: string) => fetch(`${baseUrl}/${key}`).then(res => res.json())
}

export function CSVExportScheduler() {
  const [csvUrl, setCsvUrl] = useCsvUrl(
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:4000/v1'
      : 'https://csv.yanzi.cloud/v1',
  )
  const apiUrl = `${csvUrl}/data-sources`
  const sessionId = useCirrusSessionId()
  const { host } = useCirrus()
  const [columns, setColumns] = useState<{ variableName: VariableName; aggregation: string }[]>([])

  const [timeStart, setTimeStart] = useState(format(subDays(Date.now(), 1), "yyyy-MM-dd'T'HH:mm"))
  const [timeEnd, setTimeEnd] = useState(format(Date.now(), "yyyy-MM-dd'T'HH:mm"))
  const [did, setDid] = useState('')
  const [slotSize, setSlotSize] = useState(0)
  const [locationId, setLocationId] = useState('')
  const [modal, popup] = useErrorAlert()

  const [mutateAsync, { isLoading }] = useMutation(
    async () => {
      const timeStartNumber = parse(timeStart, "yyyy-MM-dd'T'HH:mm", Date.now()).getTime() // TODO Investigate reference date
      const timeEndNumber = parse(timeEnd, "yyyy-MM-dd'T'HH:mm", Date.now()).getTime() // TODO Investigate reference date
      if (isNaN(timeStartNumber) || isNaN(timeEndNumber)) {
        throw new Error('Invalid time')
      }

      const cols = columns.map(({ variableName, aggregation }) => ({
        aggregation,
        dataSourceAddress: {
          resourceType: 'DataSourceAddress',
          variableName: {
            resourceType: 'VariableName',
            name: variableName,
          },
          did,
          locationId,
        },
      }))

      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
        body: JSON.stringify({
          columns: cols,
          sessionId,
          host,
          slot: slotSize,
          timeStart: timeStartNumber,
          timeEnd: timeEndNumber,
        }),
      })
      if (!response.ok) {
        let json = null
        try {
          json = await response.json()
        } catch {
          throw new Error(`Failed with status code ${response.status}: ${response.statusText}`)
        }
        const error = new Error()
        error.message = json.message
        error.name = `${response.status}: ${json.error}`
        throw error
      }
      const json = await response.json()
      const path = json?.path
      if (!path) {
        throw new Error('Unexpected response from server.')
      }

      const url = `${csvUrl}${path}`
      return url
    },
    { throwOnError: true },
  )
  const [downloadUrl, setDownloadUrl] = useState<null | string>(null)
  const download = useCallback(async () => {
    try {
      const url = await mutateAsync()
      if (!url) {
        throw new Error('Unexpected error')
      }
      setDownloadUrl(url)
      // saveAs(url, 'export.csv')
    } catch (e) {
      if (e?.message?.includes?.('No samples matched the selection')) {
        popup('Cound not find any samples matching the given selection.')
      } else {
        popup('An error occurred, please try again. Contact Yanzi Support if the problem persists.')
      }
    }
  }, [mutateAsync, popup])

  const ref = useRef<HTMLButtonElement>(null)

  return (
    <ReactQueryConfigProvider config={{ queries: { queryFn: createDefaultQueryFunction(csvUrl) } }}>
      {modal}
      {downloadUrl ? (
        <Modal leastDestructiveRef={ref} onDismiss={() => setDownloadUrl(null)}>
          <ModalHeader>File generated</ModalHeader>
          <ModalBody>
            <Box mt={3}>
              <Button
                ref={ref}
                as={props => (
                  <a href={downloadUrl} download="export.csv" {...props}>
                    {props.children}
                  </a>
                )}
              >
                <FontAwesomeIcon icon={faDownload} /> Download file
              </Button>
            </Box>
          </ModalBody>
        </Modal>
      ) : null}
      <Container center py={4}>
        <h1>
          Yanzi CSV Exporter{' '}
          <Badge backgroundColor="accent" color="accentBackgroundedText">
            Beta
          </Badge>
          <Badge ml={2} backgroundColor="accent" color="accentBackgroundedText">
            Free during preview
          </Badge>
        </h1>
        <Form pt={3} onSubmit={download}>
          <BasicGrid numColumns={2}>
            <Capability has="developer">
              <GridItem colSpan={2}>
                <Card>
                  <CardContent>
                    <FormGroup id="csv-url" my={0} position="relative">
                      <Label>
                        CSV app url <CapabilityBadge text />
                      </Label>
                      <TextInput value={csvUrl} onChange={e => setCsvUrl(e.target.value)} />
                    </FormGroup>
                  </CardContent>
                </Card>
              </GridItem>
            </Capability>

            <ErrorBoundary
              fallbackRender={({ resetErrorBoundary }) => (
                <Card>
                  <CardContent>
                    An error occurred. Please try again.{' '}
                    <Button onClick={resetErrorBoundary}>Try again</Button>
                  </CardContent>
                </Card>
              )}
            >
              <GridItem>
                <Card
                  opacity={isLoading ? 0.3 : 1}
                  style={isLoading ? { pointerEvents: 'none', userSelect: 'none' } : {}}
                >
                  <CardHeader>
                    <h4>Select device</h4>
                  </CardHeader>
                  <CardContent>
                    <Stack direction="column" spacing={3}>
                      <ErrorBoundary
                        fallbackRender={({ resetErrorBoundary }) => (
                          <>
                            An error occurred. Please try again.{' '}
                            <Button onClick={resetErrorBoundary}>Try again</Button>
                          </>
                        )}
                      >
                        <LocationSelector setLocationId={setLocationId} locationId={locationId} />
                      </ErrorBoundary>
                      <UnitSelector
                        locationId={locationId}
                        selectedId={did}
                        onSelect={d => {
                          setDid(d)
                          setColumns([])
                        }}
                      />
                    </Stack>
                  </CardContent>
                </Card>
              </GridItem>

              <Card
                opacity={isLoading ? 0.3 : 1}
                style={isLoading ? { pointerEvents: 'none', userSelect: 'none' } : {}}
              >
                <CardHeader>
                  <h4>Format specification</h4>
                </CardHeader>
                <CardContent>
                  <Stack direction="column" spacing={3}>
                    <SlotSizeSelector slotSize={slotSize} setSlotSize={setSlotSize} />

                    <ErrorBoundary fallbackRender={() => <>An error occurred.</>}>
                      <VariableNameSelector
                        slotSize={slotSize}
                        locationId={locationId}
                        did={did}
                        setColumns={setColumns}
                        columns={columns}
                      />
                    </ErrorBoundary>

                    <FormGroup>
                      <Label htmlFor="timeStart">Start time</Label>
                      <input
                        type="datetime-local"
                        name="timeStart"
                        id="timeStart"
                        onChange={e => setTimeStart(e.target.value)}
                        value={timeStart}
                        required
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label htmlFor="timeEnd">End time</Label>
                      <input
                        type="datetime-local"
                        name="timeEnd"
                        id="timeEnd"
                        onChange={e => setTimeEnd(e.target.value)}
                        value={timeEnd}
                        required
                      />
                    </FormGroup>
                    <Button type="submit" disabled={!did || !locationId}>
                      {isLoading ? <BeatLoader color="rgba(125,125,125)" /> : 'Generate CSV'}
                    </Button>
                  </Stack>
                </CardContent>
              </Card>
            </ErrorBoundary>
          </BasicGrid>
        </Form>
      </Container>
    </ReactQueryConfigProvider>
  )
}
