import { gql, useQuery } from '@apollo/client'
import { useDebounce } from '@yanzi/react'
import { Box, Button, FormGroup, Label, Spinner, Stack, TextInput } from '@yanzi/react-ui'
import React, { useMemo, useState } from 'react'
import { Units, UnitsVariables } from './__types__/Units'

export function UnitSelector({
  onSelect,
  selectedId,
  locationId,
}: {
  locationId: string
  selectedId: string
  onSelect: (id: string) => void
}) {
  const [query, setQuery] = useState('')

  const debouncedQuery = useDebounce(query, 300)

  const { data, loading } = useQuery<Units, UnitsVariables>(UNITS_QUERY, {
    context: { locationId },
    variables: { locationId, query: debouncedQuery },
    skip: !locationId || debouncedQuery.length < 3,
  })

  const units = useMemo(() => {
    return data?.location?.units.list
  }, [data?.location?.units.list])

  if (!locationId) {
    return null
  }

  return (
    <>
      <FormGroup id="unitId">
        <Label>Unit</Label>
        <TextInput
          value={query}
          onChange={e => setQuery(e.target.value)}
          placeholder="Type to search for units..."
        />
      </FormGroup>
      <Stack direction="column">
        {!units && loading ? <Spinner /> : null}
        {units?.map(unit => (
          <Button
            type="button"
            onClick={() => onSelect(unit.unitAddress!.did!)}
            key={unit.unitAddress?.did ?? undefined}
            backgroundColor={unit.unitAddress!.did === selectedId ? 'accent' : 'transparent'}
            color={unit.unitAddress!.did === selectedId ? 'accentBackgroundedText' : 'cardText'}
            fontWeight="inherit"
            textAlign="left"
            _hover={
              unit.unitAddress!.did === selectedId
                ? {}
                : {
                    backgroundColor: 'rgba(125,125,125,0.1)',
                  }
            }
          >
            <Box fontWeight="bold">
              {unit.name} <span style={{ fontSize: '10px' }}>{unit.unitTypeFixed}</span>
            </Box>
            <Box fontSize="xs">{unit.unitAddress!.did}</Box>
          </Button>
        ))}
        {units?.length === 0 ? (
          <Box>We could not find a unit matching that search string. Please try another one.</Box>
        ) : null}
      </Stack>
    </>
  )
}

export const UNITS_QUERY = gql`
  query Units($locationId: String!, $query: String!) {
    location(locationId: $locationId) {
      key
      units(search: $query, first: 10) {
        list {
          key
          unitAddress {
            key
            did
          }
          name
          unitTypeFixed
          assetParent {
            key
            name
          }
        }
      }
    }
  }
`
